var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pro-menu-layout',[_c('div',{staticClass:"q-pa-md"},[_c('pro-deck',{attrs:{"title":_vm.getRes('Form.Field.TechScoringSheet'),"cards":_vm.techAssessorResponseList,"hide-count-badge":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('q-btn',{attrs:{"no-caps":"","padding":"xs md","outline":"","color":"primary","label":_vm.getRes('System.Button.ViewPreviousRecords'),"to":{
            name: 'DocumentViewAuditTrails',
            query: {
              description: 'Technical Scoring',
            },
          }}})]},proxy:true},{key:"default",fn:function(ref){
          var assessorResponse = ref.card;
return [_c('pro-deck-card',{attrs:{"title":assessorResponse.displayName,"date":assessorResponse.dateSubmitted
              ? new Date(assessorResponse.dateSubmitted)
              : null,"actions":_vm.getAssessorActions(assessorResponse)}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }