<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('Form.Field.TechScoringSheet')"
        :cards="techAssessorResponseList"
        hide-count-badge
      >
        <template #action>
          <q-btn
            no-caps
            padding="xs md"
            outline
            color="primary"
            :label="getRes('System.Button.ViewPreviousRecords')"
            :to="{
              name: 'DocumentViewAuditTrails',
              query: {
                description: 'Technical Scoring',
              },
            }"
          />
        </template>

        <template v-slot="{ card: assessorResponse }">
          <pro-deck-card
            :title="assessorResponse.displayName"
            :date="
              assessorResponse.dateSubmitted
                ? new Date(assessorResponse.dateSubmitted)
                : null
            "
            :actions="getAssessorActions(assessorResponse)"
          />
        </template>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard },
  props: {
    stage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      techAssessorResponseList: [],
      uploadScoreSheetWorkflowInfo: null,
      uploadScoreSheetNoLoginWorkflowInfo: null,
      currentActiveStage: null,
    };
  },
  methods: {
    getAssessorActions(assessorResponse) {
      let actions = [];

      if (
        this.currentActiveStage !== null &&
        parseInt(this.stage) === this.currentActiveStage &&
        assessorResponse.isDoiPassed
      ) {
        if (assessorResponse.isExternalAssessor) {
          if (this.uploadScoreSheetNoLoginWorkflowInfo?.visible) {
            actions.push({
              key: "upload",
              label: this.getRes("System.Button.Upload"),
              to: this.getViewNoLoginUserFormRoute(
                assessorResponse,
                this.uploadScoreSheetNoLoginWorkflowInfo.workflowCode
              ),
            });
          }
        } else {
          if (this.uploadScoreSheetWorkflowInfo?.visible) {
            actions.push({
              key: "upload",
              label: this.getRes("System.Button.Upload"),
              to: this.getViewUserFormRoute(
                assessorResponse,
                this.uploadScoreSheetWorkflowInfo.workflowCode
              ),
            });
          }
        }
      }

      if (assessorResponse.dateSubmitted) {
        actions.push({
          processId: assessorResponse.processId,
        });
      }

      return actions;
    },
    getViewUserFormRoute(assessorResponse, workflowCode) {
      return {
        name: "DocumentViewUserForm",
        params: {
          mode: "Edit",
          code: workflowCode,
          userId: assessorResponse.hcmUserId,
        },
      };
    },
    getViewNoLoginUserFormRoute(assessorResponse, workflowCode) {
      return {
        name: "DocumentViewNoLoginUserForm",
        params: {
          mode: "Edit",
          code: workflowCode,
          noLoginUserId: assessorResponse.noLoginUserId,
        },
      };
    },
    loadTechnicalAssessorResponseList() {
      this.$proSmart.formBuilder
        .getTechnicalAssessorResponseList(
          this,
          this.$route.params.id,
          this.stage
        )
        .then((techAssessorResponseList) => {
          this.techAssessorResponseList = techAssessorResponseList;
        });
    },
  },
  created() {
    this.$watch("stage", this.loadTechnicalAssessorResponseList, {
      immediate: true,
    });

    this.$proSmart.tender
      .getWorkflowCode(this, this.$route.params.id, [
        "upload_technical_score_sheet",
        "upload_technical_score_sheet_no_login",
      ])
      .then(([uploadWorkflowInfo, uploadNoLoginWorkflowInfo]) => {
        this.uploadScoreSheetWorkflowInfo = uploadWorkflowInfo;
        this.uploadScoreSheetNoLoginWorkflowInfo = uploadNoLoginWorkflowInfo;
      });

    this.$proSmart.tender
      .getCurrentActiveAssessmentStageNum(this, this.$route.params.id)
      .then((activeStage) => {
        this.currentActiveStage = activeStage.stageNum;
      });
  },
};
</script>
